import {
  Control,
  FieldErrors,
  FieldValues,
  Path,
  useFormState,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Typography } from "@dentlink/ui";

interface FormErrorMessageProps<T extends FieldValues> {
  name: Path<T>;
  className?: string;
  errors?: FieldErrors;
  control?: Control<T>;
}

const FormErrorMessage = <T extends FieldValues>({
  errors,
  name,
  className,
  control,
}: FormErrorMessageProps<T>) => {
  const { errors: formErrors } = useFormState({ control, name });

  return (
    <ErrorMessage
      errors={errors || formErrors}
      name={name}
      render={({ message }) => (
        <Typography variant="body2" color="error" className={className}>
          {message}
        </Typography>
      )}
    />
  );
};
export default FormErrorMessage;

import { LogoBlack } from "@dentlink/icons";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { NextPageContext } from "next";
import { useTranslation } from "next-i18next";
import { LoginForm } from "src/components/LoginForm";
import Link from "next/link";
import { Divider, Typography } from "@dentlink/ui";
import { PageBackground } from "src/layouts";
import { signOut, useSession } from "next-auth/react";
import useRedirectTo from "src/common/hooks/useRedirectTo";
import Head from "next/head";
import SignupSuccessUI from "src/components/SignupForm/SignupSuccessUI";
import { useCallback, useState } from "react";
import useIsMobile from "src/common/hooks/useIsMobile";
import DesktopLandingPageLayout from "src/layouts/DesktopLandingPageLayout";
import { useRouter } from "next/router";

export const getStaticProps = async ({ locale }: NextPageContext) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ["common", "signin"])),
    },
  };
};

function useRedirectNewUser() {
  const session = useSession();
  const isNewUser = session.data?.type === "new";
  const [isGoogleSignupDialogOpen, setGoogleDialogState] = useState(false);

  const askGoogleSignup = useCallback(async () => {
    if (isNewUser && !isGoogleSignupDialogOpen) {
      setGoogleDialogState(true);
      // TODO : change this to custom dialog box
      const allowed = confirm("You are new user. Do you want to signup?");
      if (allowed) {
        return true;
      }
      await signOut({ redirect: false });
      setGoogleDialogState(false);
    }
    return false;
  }, [isGoogleSignupDialogOpen, isNewUser]);

  useRedirectTo("/signup", { condition: askGoogleSignup });
}

export default function LoginPage() {
  const { t } = useTranslation("signin");
  const router = useRouter();
  const isFromSampling = router.query.from === "sampling";

  useRedirectNewUser();

  const isMobile = useIsMobile({ breakPointWidth: 1024 });

  if (isMobile) {
    return (
      <>
        <Head>
          <title>Portal | Dentlink International</title>
        </Head>
        <PageBackground className="py-[72px]">
          <div className="mx-auto flex w-full max-w-[640px] flex-col justify-start overflow-y-auto px-4">
            <LogoBlack className="h-9 w-[164px]" />
            <Typography
              size="xl"
              color="blueGray"
              className="mt-12 leading-[41px]"
            >
              {t("welcome_text")}
            </Typography>
            <LoginForm />
            <Divider className="mt-4" />
            <div className="mt-2 flex justify-center text-sm">
              <Typography size="inherit">
                {t("not_a_member_question")}
              </Typography>
              &nbsp;
              <Link passHref href="/signup" legacyBehavior>
                <Typography
                  size="inherit"
                  color="primary"
                  className="cursor-pointer"
                >
                  {t("signup_link")}
                </Typography>
              </Link>
            </div>
          </div>
          <SignupSuccessUI />
        </PageBackground>
      </>
    );
  }
  return (
    <>
      <DesktopLandingPageLayout>
        <div className="mx-auto flex w-full max-w-[340px] flex-col justify-center overflow-y-auto px-[16px]">
          <Typography size="xl" weight="bold" className="text-center">
            Sign in to Dentlink
          </Typography>
          <LoginForm from={router.query.from} />
          <Divider className="mt-[16px]" />
          {!isFromSampling && (
            <div className="mt-[8px] flex justify-center text-sm">
              <Typography size="inherit">
                {t("not_a_member_question")}
              </Typography>
              &nbsp;
              <Link passHref href="/signup" legacyBehavior>
                <Typography
                  size="inherit"
                  color="primary"
                  className="cursor-pointer"
                >
                  {t("signup_link")}
                </Typography>
              </Link>
            </div>
          )}
        </div>
        <SignupSuccessUI />
      </DesktopLandingPageLayout>
    </>
  );
}

import { Button, PasswordInput, TextInput, Typography } from "@dentlink/ui";
import FormErrorMessage from "src/common/components/FormErrorMessage";
import { signIn } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import GoogleGLogo from "public/assets/google_g_logo.svg";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import classNames from "classnames";

type LoginInformations = {
  ["email"]: string;
  ["password"]: string;
};

interface LoginFormProps {
  from?: string | string[];
  page?: string;
}

const LoginForm = ({ from, page }: LoginFormProps) => {
  const { t } = useTranslation("signin");
  const router = useRouter();

  const { register, handleSubmit, setError, control } =
    useForm<LoginInformations>();

  const onSubmit = async (data: LoginInformations) => {
    const callbackUrl = from === "sampling" ? "/sample" : undefined;
    const res = await signIn<"credentials">("credentials", {
      ...data,
      redirect: callbackUrl ? undefined : false,
      callbackUrl,
    });

    if (res?.url) await router.push(res.url);

    if (res?.status === 401) {
      setError("password", {
        type: "manual",
        message: t("invalid_credentials"),
      });
    }
  };

  const onGoogleSignIn = async () => {
    await signIn("google", { redirect: false });
  };

  const isShowGoogleSignIn = from !== "sampling" && page !== "sample";

  return (
    <form
      className={classNames(
        page === "sample" ? "mt-[88px]" : "mt-8 desktop:mt-10"
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <TextInput
        fullWidth
        {...register("email")}
        placeholder={t("email_placeholder")}
        autoComplete="username"
        type="email"
      />
      <PasswordInput
        fullWidth
        {...register("password")}
        placeholder={t("password_placeholder")}
        className="mt-6"
        autoComplete="current-password"
        maxLength={20}
      />
      <FormErrorMessage control={control} name="password" className="mt-2" />
      <div className="mt-2 flex flex-col">
        <Link passHref href="/password/assistance" legacyBehavior>
          <Typography
            renderAs="a"
            size="sm"
            className="cursor-pointer self-end"
          >
            {t("find_password")}
          </Typography>
        </Link>
      </div>

      <div
        className={classNames(
          "flex flex-col gap-3",
          page === "sample" ? "mt-[62px]" : "mt-6"
        )}
      >
        <Button type="submit" variant="contained" color="primary" fullWidth>
          {t("signin_button")}
        </Button>
        {isShowGoogleSignIn && (
          <Button
            type="button"
            onClick={onGoogleSignIn}
            variant="contained"
            color="black"
            fullWidth
            startIcon={<GoogleGLogo />}
          >
            {t("signin_button_google")}
          </Button>
        )}
      </div>
    </form>
  );
};

export default LoginForm;

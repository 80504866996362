import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

type RedirectOptions = {
  condition: boolean | (() => Promise<boolean>);
};

export default function useRedirectTo(
  path: string,
  { condition }: RedirectOptions
) {
  const router = useRouter();

  const checkConditionAndRedirect = useCallback(async () => {
    if (typeof condition === "function") {
      if (await condition()) {
        router.push(path);
      }
    } else {
      if (condition) {
        router.push(path);
      }
    }
  }, [condition, path, router]);

  useEffect(() => {
    checkConditionAndRedirect();
  }, [path, router, condition, checkConditionAndRedirect]);
}

import useIsMobile from "src/common/hooks/useIsMobile";
import { BottomSheet, Modal } from "@dentlink/ui";
import { useDispatch, useSelector } from "react-redux";
import { openSignupCompleteUISelector } from "src/lib/SignupForm/redux/signupPageSelectors";
import { setOpenSignupCompleteUI } from "src/lib/SignupForm/redux/signupPageSlice";

const TITLE = "Account Created";

const SignupSuccessUI = () => {
  const openSignupCompleteUI = useSelector(openSignupCompleteUISelector());
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const action = {
    label: "OK",
    color: "primary" as const,
    variant: "contained" as const,
    onClick: () => {
      dispatch(setOpenSignupCompleteUI({ value: false }));
    },
  } as const;

  if (isMobile) {
    return (
      <BottomSheet
        title="Account Created"
        isOpen={openSignupCompleteUI}
        actions={action}
        withCheck
      />
    );
  }

  return (
    <Modal isOpen={openSignupCompleteUI}>
      <Modal.InfoSuccess
        onClick={action.onClick}
        label={action.label}
        title={TITLE}
      />
    </Modal>
  );
};

export default SignupSuccessUI;

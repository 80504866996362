import { createSelector } from "@reduxjs/toolkit";
import { SignupPageState } from "src/lib/SignupForm/redux/signupPageSlice";
import { RootState } from "src/redux/store";

const signupPageStateSelector = (state: RootState): SignupPageState =>
  state.signupPage;

export const openSignupCompleteUISelector = () =>
  createSelector(
    [signupPageStateSelector],
    (signupPage) => signupPage.openSignupCompleteUI
  );
